<template>
  <div class="main_lci_wrapper">
    <div class="main_layout d-flex">
      <div :class="['cards_and_logo', { coll: hideShowSidebar }]">
        <div class="render_action">
          <img src="@/assets/new-theme/PLC-Logo.svg" alt="" />
          <img
            v-if="hideXIcon"
            @click="hideAndShowSidebar"
            role="button"
            src="@/assets/new-theme/lci/x-close.png"
            alt=""
          />
        </div>
        <div v-if="hideShowSidebar == false">
          <div class="cards_wrapper" v-for="(item, i) in cardItems" :key="i">
            <LciCard :item="item"></LciCard>
          </div>
        </div>
        <div
          class="mt-5 absolute_question_mark"
          v-if="!hideXIcon"
          :class="{ make_absolute: hideShowSidebar == true }"
        >
          <div
            @click="hideAndShowSidebar"
            role="button"
            class="mark_tooltip"
            v-tooltip.top-center="`${localization('Useful tips and support')}`"
          >
            <img src="@/assets/new-theme/icons/help-circle.png" alt="" />
          </div>
        </div>
      </div>
      <div class="tabs_wrapper">
        <div class="step_number_and_questions_number">
          <span class="st_number_value">{{ localization('Step') }} {{ this.active + 1 }} </span>
          <span class="st_questions_number"
            >{{ currentQuestionCount + ' ' + localization('Sentences')}} </span
          >
        </div>

        <!-- Start Questions -->
        <div class="tabs_steps mt-1">
          <span
            class="d-block step"
            :class="{ active: isActive(tab.index) }"
            v-for="tab in tabs"
            :key="tab.index"
          ></span>
        </div>
        <!-- Start Loop -->
        <div class="all_questions ">
          <div
            v-for="(tab, tabIndex) in tabs"
            :key="tabIndex"
            v-show="active === tab.index"
          >
            <div
              class="every_question"
              v-for="(question, i) in tab.questions"
              :key="question.id"
            >
              <div class="question_header">
                <div class="main_title">
                  <span>{{ question.number }}. </span>{{ question.title }}
                </div>
                <div class="main_icons">
                  <!-- //? start text to speech  -->
                  <audio controls id="audio" class="d-none">
                    <source id="audio-source" type="audio/mp3" />
                  </audio>
                  <div
                    @click="text_speech_audio(question.title)"
                    class="every_icon"
                    role="button"
                    v-tooltip.top-center="`${localization('Read out loud')}`"
                  >
                    <img src="@/assets/new-theme/icons/volume-max.png" alt="" />
                    <span>{{localization('Listen')}}</span>
                  </div>
                </div>
              </div>
              <!-- Answers -->
              <div class="mt-3">
                <template v-if="question.type === 0">
                  <el-radio-group
                    v-model="answers[question.question_id].answer_id"
                    class="main_radio_group"
                  >
                    <el-radio-button
                      v-for="(answer, i) in question.questions_options"
                      :key="i"
                      :label="i + 1"
                    >
                      {{ answer }}
                    </el-radio-button>
                  </el-radio-group>
                </template>
                <template v-else>
                  <el-input
                    type="textarea"
                    v-model="answers[question.question_id].answer_text"
                    rows="3"
                  ></el-input>
                </template>
              </div>
            </div>

            <!-- Start Buttons For Next And Previous -->
            <div class="footer-buttons">
              <div class="step-buttons">
                <button v-if="tabIndex > 0" @click="prevTab" class="back-btn">
                  <img
                    src="@/assets/new-theme/icons/arrow-narrow-left-active-Button.png"
                    alt="edit icon"
                  />
                </button>
                <button
                  @click="nextTab"
                  v-if="tabIndex !== tabs.length - 1"
                  class="continue-btn"
                >
                  {{ localization('Save and Continue') }}  
                </button>

                <button
                  v-if="tabIndex < tabs.length - 1"
                  @click="nextTab"
                  class="back-btn"
                >
                  <img
                    src="@/assets/new-theme/icons/arrow-narrow-right-Disable-Button.png"
                    alt="edit icon"
                  />
                </button>

                <button
                  v-if="tabIndex === tabs.length - 1"
                  @click="submitAnswers"
                  class="lci_btn save  mx-4"
                >
                  {{ localization('Submit LCI') }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- End Questions -->

        <el-dialog :visible.sync="showEditDialog" top="4vh" width="40%">
          <div class="dialog_style">
            <div class="mb-5 img_radius">
              <div>
                <img
                  role="button"
                  src="@/assets/new-theme/icons/edit-05.png"
                  alt=""
                />
              </div>
            </div>
            <div>
              <h3 class="title mb-2">Edit an LCI question</h3>
              <p class="desc">
                If you wish to make a statement more accurate to you, insert
                your changes below.
              </p>
            </div>
            <div class="mt-4">
              <p class="desc2">
                The statement #01
              </p>
              <textarea
                class="text_area"
                v-model="questionModalData.title"
              ></textarea>
            </div>
            <div class="lci_modal_buttons mt-4">
              <button
                @click="closeEditLciDialog"
                class="lci_btn_modal_cancel mx-4"
              >
                Cancel
              </button>
              <button @click="closeEditLciDialog" class="lci_btn save mx-4">
                Confirm
              </button>
            </div>
          </div>
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
export default {
  name: "LciQuestions",
  data: () => ({
    hideShowSidebar: false,
    hideXIcon: true,
    cardItems: [
      {
        src: require("@/assets/new-theme/icons/clock.png"),
        title: "Take the time you need",
        description:
          "While there are no right or wrong answers, there are answers that are more accurate to who you are than others."
      },
      {
        src: require("@/assets/new-theme/icons/check-circle-broken.png"),
        title: "Think About Your Answers",
        description:
          // 'Make every attempt to avoid answering "Sometimes" unless the answer truly is "Sometimes".'
          "There are times when “Sometimes” is appropriate, but please do not use it as an excuse not to decide."
      },
      {
        src: require("@/assets/new-theme/icons/target-01.png"),
        title: "Be as accurate and detailed as possible",
        description:
          "Try to be as accurate and detailed as possible in responding to the open-end questions at the end of the questionnaire."
      }
    ],
    testval: [],
    questionslist: [],
    arrOfIDs: [],
    modals: {
      basic: false,
      centered: false,
      custom: false,
      confirm: false
    },
    answers: [
      "Never Ever",
      "Almost Never",
      "Sometimes",
      "Almost Always",
      "Always"
    ],
    one: 0,
    result: [],
    editable: false,
    answersall: {},
    userLciCms: null,
    checkUserScore: false,
    currentIndex: 0,
    checkUserScoreTwo: false,
    // NEW THEME
    step: 1,
    allQuestionsList: [],
    answers: [],
    tabs: [],
    active: 0,
    activeTabs: [0],
    currentTab: 0,
    showEditDialog: false,
    questionModalData: {}
  }),
  // created() {
  //   this.initializeTabs();
  //   this.initializeAnswers();
  // },
  computed: {
    id() {
      return this.$route.params.id ? this.$route.params.id : this.auth().id;
    },
    currentQuestionCount() {
      return this.tabs[this.active]
        ? this.tabs[this.active].questions.length
        : 0;
    }
  },

  mounted() {
    this.$store.dispatch("user/get_questions", { id: this.id }).then(_ => {
      this.questionslist = this.$store.state.user.questions;
      for (var i in this.questionslist) {
        this.questionslist[i].number = +i + 1;
        this.questionslist[i].title = this.questionslist[i].question_body
          ? this.questionslist[i].question_body
          : this.questionslist[i].title;
      }
      this.allQuestionsList = this.$store.state.user.questions;
      for (let i = 0; i < this.allQuestionsList.length; ) {
        const chunkSize = this.tabs.length === 4 ? 3 : 7; // Step 5 will have 3 questions, others 7
        const questionsChunk = this.allQuestionsList.slice(i, i + chunkSize);
        this.tabs.push({
          index: this.tabs.length,
          questions: questionsChunk
        });
        i += chunkSize;
      }

      this.allQuestionsList.forEach(question => {
        this.$set(this.answers, question.question_id, {
          id: question.id,
          answer_id: question.answer_id || null,
          answer_text: question.answer_text || null,
          question_id: question.question_id,
          question_body: question.question_body || null
        });
      });
    });
  },
  methods: {
    validateCurrentTab() {
      const currentTab = this.tabs[this.active];
      let isValid = true;
      currentTab.questions.forEach(question => {
        const answer = this.answers[question.question_id];
        if (question.type === 0) {
          // Radio button validation
          if (!answer || !answer.answer_id) {
            isValid = false;
          }
        } else {
          // Text input validation
          if (!answer || !answer.answer_text.trim()) {
            isValid = false;
          }
        }
      });
      return isValid;
    },

    hideAndShowSidebar() {
      this.hideShowSidebar = !this.hideShowSidebar;
      this.hideXIcon = !this.hideXIcon;
    },
    openEditLciDialog(question) {
      this.showEditDialog = true;
      this.questionModalData = question;
    },
    closeEditLciDialog() {
      this.showEditDialog = false;
    },
    switchToNextTab() {},
    isActive(index) {
      return this.activeTabs.includes(index);
    },
    nextTab() {
      if (this.validateCurrentTab()) {
        this.submitAnswers();
      } else {
        Swal.fire({
          text: this.localization(
            "Please answer all questions before proceeding."
          ),
          icon: "error",
          showCancelButton: false,
          showConfirmButton: true
        });
      }
    },
    prevTab() {
      if (this.active > 0) {
        this.active--;
      }
      this.activeTabs.pop(this.active);

      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    },

    submitAnswers() {
      let mainAnswers = { answers: Object.values(this.answers) };

      const allAnswered = mainAnswers.answers.every(
        element => element.answer_text || element.answer_id
      ) && this.currentIndex == this.tabs.length - 1;

      if(allAnswered == false){
        this.currentIndex = this.currentIndex + 1
      }

      const formattedAnswers = mainAnswers.answers.map(element => {
        if (element.answer_text) {
          element.answer_id = null;
        } else if (element.answer_id) {
          element.answer_text = null;
        }
        return {
          id: element.id,
          answer_id: element.answer_id,
          question_id: element.question_id,
          question_body:
            this.arrOfIDs.indexOf(element.id) > -1 ? element.title : null,
          answer_text: element.answer_text
        };
      });
      const finalPayload = {
        query: {
          answers: formattedAnswers,
          status: allAnswered
        }
      };
      this.$store
        .dispatch("user/save_answers", finalPayload)
        .then(response => {
          if (response.status === "done") {
            this.$router.push({ name: "Personalize Your Profile plp" });
          }
          if (response.status === "success") {

            if (this.active < this.tabs.length - 1) {
              this.active++;
            }
            this.activeTabs.push(this.active);
            window.scrollTo({
              top: 0,
              behavior: "smooth"
            });
          }
        })
        .catch(err => {
          if (err.response?.data?.error === "LCI error message") {
            Swal.fire({
              text: this.localization("LCI error message"),
              icon: "error",
              confirmButtonText: this.localization("Ok"),
              confirmButtonClass: "btn btn-success",
              buttonsStyling: false
            });
          }
        });
    },
    filter(ques) {
      this.arrOfIDs.push(ques.id);
    },
    save() {
      // check if user filled all answers
      let allQuestionsAnswered = this.questionslist.every(ques => {
        // debugger;
        //  if no answer neither at anwer id nor at answer text properties
        if (ques.answer_id == null && ques.answer_text == null) {
          return false;
        }
        return true;
      });

      if (allQuestionsAnswered) {
        for (let index = 0; index < this.questionslist.length; index++) {
          const element = this.questionslist[index];

          if (element.answer_text) {
            element.answer_id = null;
          } else if (element.answer_id) {
            element.answer_text = null;
          }
          this.result[index] = {
            id: element.id,
            answer_id: element.answer_id,
            question_id: element.question_id,
            question_body:
              this.arrOfIDs.indexOf(element.id) > -1 ? element.title : null,
            answer_text: element.answer_text
          };
        }
        // check if user answered all question with same answers
        // variables to check if value changed
        let valueChoiceChanged = false,
          valueTextChanged = false;
        // loop to check there are at least one differnet answer at both type of questions
        for (let i = 1; i < this.result.length; i++) {
          // current & previous answers of both type of questios
          let answerId = this.result[i].answer_id,
            answerTxt = this.result[i].answer_text,
            prevAnswerId = this.result[i - 1].answer_id,
            prevAnswerTxt = this.result[i - 1].answer_text;
          if (answerId === null) {
            // check for text questions
            // null coz when we move from multichoice to text questions
            if (prevAnswerTxt !== answerTxt && prevAnswerTxt !== null)
              // if one value changed
              valueTextChanged = true;
          } else {
            // check for Multichoice quesitons
            if (answerId !== prevAnswerId) valueChoiceChanged = true;
          }
        }
        // only execute if answers are unique
        if (valueChoiceChanged && valueTextChanged) {
          this.answersall = {
            answers: this.result,
            status: true
          };
          this.$store
            .dispatch("user/save_answers", {
              query: this.answersall
            })
            .then(response => {
              if (response.status == "done") {
                this.$router.push("/profile");
              }
            })
            .catch(err => {
              if (err.response.data.error == "LCI error message") {
                Swal.fire({
                  text: `${this.localization("LCI error message")}`,
                  type: "error",
                  icon: "error",
                  confirmButtonText: `${this.localization("Ok")}`,
                  confirmButtonClass: "btn btn-success ",
                  buttonsStyling: false
                });
              }
            });
        } else {
          Swal.fire({
            // text: "Kindly input uniques answers",
            text: `${this.localization("Kindly input unique answers")}`,
            icon: "error",
            showCancelButton: false,
            showConfirmButton: false,
            timer: 2000
          });
        }
      } else {
        Swal.fire({
          // text: "Please answer all questions",
          text: `${this.localization("Answer all questions")}`,
          icon: "error",
          showCancelButton: false,
          showConfirmButton: false,
          timer: 3000
        });
      }
    },

    text_speech_audio(any_text) {
      this.$store
        .dispatch("user/get_text_to_speech_audio", { text: any_text })
        .then(res => {
          let audioBlob = new Blob([res], { type: "audio/mpeg" });
          let audioURL = URL.createObjectURL(audioBlob);
          let audio = document.getElementById("audio");
          var source = document.getElementById("audio-source");
          source.src = audioURL;
          audio.load();
          audio.play();
        });
    },

    saveQuestion() {
      // check if user filled all answers
      let allQuestionsAnswered = this.questionslist.every(ques => {
        // debugger;
        //  if no answer neither at anwer id nor at answer text properties
        if (ques.answer_id == null && ques.answer_text == null) {
          return false;
        }
        return true;
      });

      // if (allQuestionsAnswered) {
      for (let index = 0; index < this.questionslist.length; index++) {
        const element = this.questionslist[index];

        if (element.answer_text) {
          element.answer_id = null;
        } else if (element.answer_id) {
          element.answer_text = null;
        }
        this.result[index] = {
          id: element.id,
          answer_id: element.answer_id,
          question_id: element.question_id,
          question_body:
            this.arrOfIDs.indexOf(element.id) > -1 ? element.title : null,
          answer_text: element.answer_text
        };
      }
      // check if user answered all question with same answers
      // variables to check if value changed
      let valueChoiceChanged = false,
        valueTextChanged = false;
      // loop to check there are at least one differnet answer at both type of questions
      for (let i = 1; i < this.result.length; i++) {
        // current & previous answers of both type of questios
        let answerId = this.result[i].answer_id,
          answerTxt = this.result[i].answer_text,
          prevAnswerId = this.result[i - 1].answer_id,
          prevAnswerTxt = this.result[i - 1].answer_text;
        if (answerId === null) {
          // check for text questions
          // null coz when we move from multichoice to text questions
          if (prevAnswerTxt !== answerTxt && prevAnswerTxt !== null)
            // if one value changed
            valueTextChanged = true;
        } else {
          // check for Multichoice quesitons
          if (answerId !== prevAnswerId) valueChoiceChanged = true;
        }
      }
      // only execute if answers are unique
      if (valueChoiceChanged) {
        this.answersall = {
          answers: this.result,
          status: false
        };
        this.$store
          .dispatch("user/save_answers", {
            query: this.answersall
          })
          .then(response => {});
      } else {
        Swal.fire({
          text: `${this.localization("Kindly input unique answers")}`,
          icon: "error",
          showCancelButton: false,
          showConfirmButton: false,
          timer: 2000
        });
      }
      // } else {
      //   Swal.fire({
      //     title: "Error!",
      //     text: "Please answer all questions",
      //     icon: "error",
      //     showCancelButton: false,
      //     showConfirmButton: false,
      //     timer: 3000
      //   });
      // }
    },
    // switchToTrueForLci() {
    //   this.checkUserScore = !this.checkUserScore;
    //   window.scrollTo(0, 0);
    // }
    backToInstructions() {
      this.$router.push("/new-theme/instructions");
    }
  }
};
</script>

<style lang="scss" scoped>
.main_lci_wrapper {
  width: 100%;
  height: 100vh;
  position: relative;
  .render_action {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  // background: #eee;
  width: 100%;
  .main_layout {
    gap: 40px;
    position: absolute;
    width: 100%;
    top: 0;
    bottom: 0;
    left: 0;
  }

  .cards_and_logo {
    background: #fcfcfd;
    max-width: 20%;
    padding: 26px 16px;
    min-width: 21%;
    position: relative;
    // transition: 0.2s;

    .absolute_question_mark {
      &.make_absolute {
        position: absolute;
        bottom: 0;
      }
    }
    .cards_wrapper {
      margin-top: 40px;
    }
  }
  .coll {
    background: #fff;
    padding: 26px 16px;
    min-width: 12%;
    transition: 0.8s;
  }
  .tabs_wrapper {
    flex-grow: 0.5;
    // display: grid;
    // align-content: center;
    // background: #eee;
    .step_number_and_questions_number {
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 2rem 0 0.75rem;
      .st_number_value {
        color: #101828;
        font-size: 22px;
        font-weight: 500;
      }
      .st_questions_number {
        color: #667085;
        font-size: 14px;
      }
    }
    .tabs_steps {
      display: flex;
      gap: 9px;
      justify-content: space-between;
      .step {
        height: 4px;
        border-radius: 8px;
        background-color: #e9d7fe;
        width: 205px;
        &.active {
          background-color: #7f56d9;
        }
      }
    }
    .all_questions {
      margin-top: 24px;
      .every_question {
        margin-bottom: 25px;
        .question_header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .main_title {
            color: #344054;
            font-weight: 400;
            font-size: 17px;
            flex-grow: 1;
            max-width: 70%;
          }
          .main_icons {
            display: flex;
            gap: 30px;
            align-items: center;
            .every_icon {
              display: flex;
              gap: 7px;
              align-items: center;
              color: #7f56d9;
              font-weight: 500;
            }
          }
        }
      }
    }
  }
}
.main_radio_group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  // flex-wrap: wrap;
}
::v-deep .el-radio-button {
  // border: 1px solid #d0d5dd;
  // width: 100%;
  min-width: 180px;
  max-width: 200px;
  border-radius: 8px !important;
  &.is-active {
    .el-radio-button__inner {
      background: #7f56d9;
      border-color: #7f56d9;
      border-radius: 8px !important;
    }
  }
}
::v-deep .el-radio-button__inner {
  width: 100%;
  border-radius: 8px !important;
  border: 0;
  border: 1px solid #d0d5dd;
  color: #344054;
  font-weight: 500;
}

// Buttons Next And Previous
.lci_btn {
  background-color: #f2f4f7;
  color: #667085;
  border-radius: 8px;
  padding: 10px 10px;
  border: 1px solid #eaecf0;
  width: 200px;
  &.save {
    background-color: #7f56d9;
    color: #fff;
  }
}
.prev_next {
  background-color: #f2f4f7;
  border-radius: 8px;
  padding: 12px 20px;
  border: 1px solid #eaecf0;
  color: #667085;
  width: 70px;
}

.mark_tooltip {
  border: 1px solid #d0d5dd;
  width: fit-content;
  padding: 12px;
  border-radius: 50%;
}

.lci_modal_buttons {
  display: flex;
  align-items: center;
  justify-content: center;
}
.lci_btn_modal_cancel {
  background-color: #fff;
  color: #667085;
  border-radius: 8px;
  padding: 12px 20px;
  border: 1px solid #eaecf0;
  width: 240px;
}

.dialog_style {
  .title {
    color: #101828;
    font-weight: 500;
    font-size: 20px;
  }
  .desc {
    color: #667085;
    font-weight: 400;
    font-size: 14px;
  }
  .desc2 {
    color: #344054;
    font-weight: 500;
    font-size: 16px;
  }
  .text_area {
    border: 1px solid #d0d5dd;
    height: 92px;
    width: 100%;
    resize: none;
    border-radius: 8px;
    color: #101828;
    padding: 15px;
    outline: none;
    word-break: break-word;
  }

  .img_radius {
    background: #f9f5ff;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.footer-buttons {
  bottom: 1rem;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  box-shadow: 0 -5px 2px 0 rgba(0, 0, 0, 0.025);
  z-index: 10;
  background: #ffff;
}
.step-buttons {
  padding: 15px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

/* Step button container styles */
.step-buttons {
  display: flex;
  gap: 16px; /* Space between buttons */
}

/* Back Button Styling */
.back-btn {
  background-color: #f9fafb;
  color: #000;
  border: 1px solid #d1d5db;
  border-radius: 8px;
  font-weight: 500;
  padding: 8px 16px;
  box-shadow: none;
  transition: all 0.3s ease;
}
.back-btn:hover {
  background-color: #5e3cba;
  color: #fff;
}

/* Continue Button Styling */
.continue-btn {
  background-color: #e5e7eb;
  color: #667085;
  border: 0;
  border-radius: 8px;
  font-weight: 500;
  padding: 8px 4rem;
  box-shadow: none;
  transition: all 0.3s ease;
}
.continue-btn:hover {
  background-color: #5e3cba;
  color: #fff;
}
</style>
